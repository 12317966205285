// colors
$primary-color: #B9D137;
$primary-color-rgb: 185, 209, 55;
$primary-color-contrast: #ffffff;
$primary-color-contrast-rgb: 255, 255, 255;

$secondary-color: #58B1D7;
$secondary-color-rgb: 56, 128, 255;
$secondary-color-contrast: #ffffff;
$secondary-color-contrast-rgb: 255, 255, 255;

$warning-color: #ffce00;
$warning-color-rgb: 255, 206, 0;
$warning-color-contrast: #ffffff;
$warning-color-contrast-rgb: 255, 255, 255;

$danger-color: #f04141;
$danger-color-rgb: 245, 61, 61;
$danger-color-contrast: #ffffff;
$danger-color-contrast-rgb: 255, 255, 255;

$dark-color: #404040;
$dark-color-rgb: 64, 64, 64;
$dark-color-contrast: #ffffff;
$dark-color-contrast-rgb: 255, 255, 255;

$medium-color: #808080;
$medium-color-rgb: 128, 128, 128;
$medium-color-contrast: #ffffff;
$medium-color-contrast-rgb: 255, 255, 255;

$light-color: #CCCCCC;
$light-color-rgb: 204, 204, 204;
$light-color-contrast: #000000;
$light-color-contrast-rgb: 0, 0, 0;

// fonts
$default-font: "Roboto", sans-serif;
$default-font-size: 16px;

// left menu
$default-logo-file: 'icons/congreet-logo-text.svg';
$bg-menu-color: #525E6A;
$bg-menu-image: 'bg-menu.png';
$menu-color: $light-color; //#D3D6D9;
$menu-color-highlight: lighten($menu-color, 25%);
$color-memu-highlight: $primary-color;
$bg-overlay: #515d69; //#525E6A;
$bg-overlay-rgb: 81,93,105;

// dashboard
$bg-dashboard-color: #525E6A;
$bg-dashboard-image: 'bg-dashboard.png';

// main content
$bg-content: #F8F8F8;
$bg-content-contrast: #FFFFFF;
$color-content: #000000;
$bg-content-logged-out: #5A6775;
$color-content-logged-out: #FFFFFF;

// global borders
$global-radius: 4px;
$global-padding: 16px;
$global-margin: 16px;

// inputs
$input-background: #FFFFFF;
$input-color: $dark-color;
$input-placeholder-color: #999999;
$input-border-color: #E7E7E7;//$light-color;
$input-height: 48px;

$form-width: 448px;

// buttons
$button-height: 48px;
